import service from "@/utils/request";

/**
 * 添加文章信息
 * @returns {AxiosPromise}
 * @param pid
 * @param current
 */
export function selectUploadInfo(pid,current) {
    return service(
        {
            url: 'uploadInfo/selectUploadInfo',
            method: 'post',
            params:{
                pid,current
            }
        })
}