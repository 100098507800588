import service from '@/utils/request'

/**
 * 获取考研教育网，一些信息
 */
/**
 * 获取地区信息
 * @param pid
 * @returns {AxiosPromise}
 */
export function getArea(pid) {
    return service({
        url: 'area/getArea',
        method: 'post',
        params: {pid}
    })
}

/**
 * 获取 考研科目分类信息
 * @param code
 * @param type
 * @returns {AxiosPromise}
 */
export function getCategory(code, type) {
    return service({
        url: 'category/getCategory',
        method: 'post',
        params: {code, type}
    })
}

/**
 * 获取开设该专业的所有学校
 * @param code
 * @returns {AxiosPromise}
 */
export function getInstitute(code, current,province,one) {
    return service({
        url: 'institute/getInstitute',
        method: 'post',
        params: {code, current,province,one}
    })
}

/**
 * 只能够使用学校信息
 * @param schoolCode
 * @param categoryCode
 * @returns {AxiosPromise}
 */
export function getInformationByCode(schoolCode,categoryCode) {
    return service({
        url: 'institute/getInformationByCode',
        method: 'post',
        params: {schoolCode,categoryCode}
    })
}
/**
 * 根据身份来获取学校的信息
 *
 * @returns {AxiosPromise}
 */
export function getSchool(province,current,level,one) {
    return service({
        url: 'school/getSchoolByCondition',
        method: 'post',
        params: {province,current,level,one}
    })
}

/**
 * 添加课程信息
 * @param course
 * @returns {*}
 */
export function addCourse(course) {
    console.log(course)
    return service(
        {
            url: 'course/addCourse',
            method: 'post',
            header: {
                "Content-Type": "application/json" //如果写成contentType会报错
            },
            data: course
        })
}

/**
 * 获取首页全部的课程
 * @returns {AxiosPromise}
 */
export function getAllCourse(current) {
    return service(
        {
            url: 'course/getAllCourse',
            method: 'post',
            params:{
                current
            }
        })
}

/**
 * 根据课程id 获取课程信息
 * @param id
 * @returns {AxiosPromise}
 */
export function getCourse(id) {
    return service(
        {
            url: 'course/getCourseById',
            method: 'post',
            params: {id}
        })
}

/**
 * 根据用户id，来获取课程所有信息
 * @param id
 * @returns {AxiosPromise}
 */
export function getCourseByUserId(userId,current) {
    return service(
        {
            url: 'course/getCourseByUserId',
            method: 'post',
            params: {userId,current}
        })
}


/**
 * 删除视频
 * @param id
 * @returns {AxiosPromise}
 */
export function deleteCourse(id) {
    return service(
        {
            url: 'course/deleteCourse',
            method: 'post',
            params: {id}
        })
}

/**
 * 更新课程信息
 * @param course
 * @returns {*}
 */
export function updateCourse(course) {
    return service(
        {
            url: 'course/updateCourse',
            method: 'post',
            header: {
                "Content-Type": "application/json" //如果写成contentType会报错
            },
            data:course
        })
}

